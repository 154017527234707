/* about.css */
.wrapper {
    margin: auto;
    max-width: rem-calc(1200);
}

.article {
width: 100%;
/*max-height: 300px;*/
box-sizing: border-box;
border-radius: 10px;
color: #505050;
background-color: white;
font-size: 1.5em;
line-height: 1.5;
padding: .5em;
}
.article p {
display: block;
margin-top: 1em;
margin-bottom: .5em;
margin-left: 0em;
margin-right: 0;
}
@media (min-width: 768px) {
    .article {
width: 740px;
}
}
.imgAbout {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:15px;
        width: 100%;
}
@media (min-width: 768px) {
    .imgAbout {
width: 740px;
}
}