h1 {
  font-size: 40px;
  color: red;
  font-family: Verdana;
}
.resetMargin {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 3px;
  margin: 0px;
}

.home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 700px) {
  .home-main {
    flex-direction: row;
    justify-content: center;
 /* margin: 0px 0px 0px;*/
  height: 600px;
}
}

.mainImg {
  /*border-radius: 15px;*/
/* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);*/
  overflow: auto;
  max-width: 100%;
  height: auto;
  /*margin: 0 auto;*/
  margin: 15px 0px 25px;
  padding-top: 10px;
/*background-color: #00438B; */
}
@media (min-width: 700px) {
  .mainImg {
   /* flex-direction: row; */
   border-radius: 0px;
  margin: 0px 0px 35px;
  /*height: 600px;*/

}
}

.responsive {
  width: 100%;
  height: auto;
  /*float:right; */
 /* height: 80vh; */
}
@media (min-width: 700px) {
  .responsive {
  width:auto;
  padding-right:10px;
  height: 600px;
}
}
.wipForm {
  margin-top: 20px;
  padding-left: 10px;
}
