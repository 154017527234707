/* forms.css */
.msgContainer {
  display: flex;
  justify-content: center;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  max-height: 400px;
  background-color: #0191c8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}
.icon {
  color: white;
  padding-right: 20px;
}
.formEndStyl {
  color: white;
  font-size: 1.3em;
  line-height: 1.3;
}
.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
