@import "https://fonts.googleapis.com/css?family=Lato:100,300,400,700";
@import "https://fonts.googleapis.com/css?family=Gruppo&display=swap";
@import url('https://fonts.googleapis.com/css?family=Aladin&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
} 
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
 /* background-color: #f3f9fb; */
 /* background-color: #74c2e1; */
  background-color: #B5E2F4;
}

textarea {
  font-size: 1em;
}
.pdngTop-2em {
  padding-top: 2em;
}
.pdngBtm-2em {
  padding-bottom: 2em;
}
.mrgnBtm {
  margin-bottom: 1em;
}
.vidContainer {
  width: 95%;
  height:100%;
  margin: 0 auto;
  margin-bottom:20px;
}
@media (min-width: 1100px) {
  .vidContainer {
  width:50%;
}
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.onEndContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.onEndDisplay {
  position:absolute;
  z-index: 10;
  font-size: 2em;
}


/*@media screen and (min-width:1000px) {
  .player-wrapper {
    width: 1000px;

  }
} */

/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
