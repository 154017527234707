/* player.css */
/*@import "https://fonts.googleapis.com/css?family=Lato:100,300,400,700";
@import "https://fonts.googleapis.com/css?family=Gruppo&display=swap");
@import ""; */

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
} */

.player {
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
  /*margin: 0 auto;*/
  margin-top: 15px;
  margin-bottom:20px;
  margin-left: 0px;
  margin-right:0px;
  padding-top: 15px;
background-color: #00438B;
}
@media (min-width: 700px) {
  .player {
  /*margin: 5px; */
  margin: auto;
  max-width:700px;
}
}
.homePlayer {
  border-radius: 15px;
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  /*overflow: auto;*/
  max-width: 100%;
  height: auto;
  /*margin: 0 auto;*/
  margin: 15px 0px 25px;
  padding-top: 15px;
background-color: #00438B;
}
@media (min-width: 700px) {
  .homePlayer {
    overflow:auto;
  margin: 0px 0px 25px;
  max-height: 600px;

}
}
.homePlayerTitle {
text-align: center;
font-family: 'Aladin';
font-size: 2em;
font-weight: 600;
color: yellow;
padding-bottom: .5em;
letter-spacing: .2em; 
}
.homePlayerSubTitle {
text-align: center;
font-family: 'Aladin';
font-size: 1.3em;
font-weight: 400;
color: yellow;
padding-bottom: 1em;
padding-left: 10px;
padding-right: 10px;
letter-spacing: .2em; 
}

/*.player::before {
  display: block;
  content: "";
  background: url(./images/playerbkgnd.jpg);
  background-size: cover;
  opacity: 0.6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
} */

.albumContainer {
  width: 100%;
  height: 200px;
  margin: 5px;
}

.albumArt {
  width: 150px;
  float: left;
  padding-right: 15px;
  padding-left: 10px;
}

.albumTitle {
  display: block;
  color: #51eaea;
  font-size: 2em;
  font-weight: 600;
 /* padding-top: .2em; */
  text-transform: uppercase;
}
.albumAuthor {
  display: block;
  color: #51eaea;
  font-size: 1.5em;
  padding-top: .5em;
  text-transform: capitalize;
}

.albumArt img {
  max-width: 100%;
  max-height: 100%;
}

.flexBreak {
  /* this creates a break to next row */
  flex-basis:100%;
  height: 0;
}

.Controls {
  /*display: -webkit-box;
  display: -ms-flexbox; */
  display: flex;
  height: 2.5em;
  box-sizing: border-box;
  /*box-shadow: inset 0.1em 0.1em 0.1em 0 rgba(255, 255, 255, 0.5), inset -0.1em -0.1em 0.1em 0 rgba(0, 0, 0, 0.5); */
  margin:0px;
  /*background: #096386; */
  opacity: 1;
  -ms-flex-pack: distribute;
  justify-content: flex-start;
  padding: 15px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background 0.125s ease;
  transition: background 0.125s ease;
}

.Controls i:hover {
  cursor: pointer;
}

.Controls .iconPlay {
  margin-left: 1em;
  border-style:solid;
  border-width: 1px;
  border-color: white;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:15px;
  padding-right:25px;
}
.Controls .iconFastBackward {
  margin-left:auto;
  color:white;
}
.Controls .iconFastForward {
  margin-left: 1em;
  margin-right:1em;
  color:white;

}
.Controls .fa-play,
.Controls .fa-pause {
  color:white;
 
  -webkit-transform: scale(1);
  transform: scale(1);
}

.dsply-track {
text-align:left;
margin-top: 1em;
  margin-bottom:1em;
  margin-left: .5em;
  padding-top:10px;
 height: 2em;
 /*width: 300px;*/
 flex-grow:2;
 font-size: 1.2em;
 font-weight: 600;
 color: white;
}
@media (max-width: 600px) {
  .dsply-track {
    padding-top:0px;
    max-width:200px;
}
}
.dsply-duration {
  margin-top: .5em;
    margin-bottom:1em;
   margin-left: .2em; 
    margin-right: .1em;
   height: 1em;
   width: 20px;
   font-size: .8em;
   font-weight: 600;
   color: white; 
}
.dsply-currentTime {
  margin-top: .5em;
 margin-left: auto; 
    margin-bottom:1em;
    margin-right: .5em;
   height: 1em;
   width: 23px;
   font-size: .8em;
   font-weight: 600;
   color: white; 
}
.dsply-span {
  margin-top: .5em;
    margin-bottom:1em;
    margin-right: .1em;
   margin-left: .1em; 
   height: 1em;
   width: 5px;
   font-size: .8em;
   font-weight: 600;
   color: white; 
}
.progressContainer {
  display: -webkit-box;
  display: -ms-flexbox; 
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items:baseline;
  box-sizing: border-box;
  opacity: 1;
  /*-ms-flex-pack: distribute;*/
  justify-content: flex-start;
  /*padding: 20px 0;*/
  /*-webkit-box-align: center; */
  /*-ms-flex-align: center;*/
  /*align-items: center; */
  /*-webkit-transition: background 0.125s ease; */
 transition: background 0.125s ease;
  /*width: 100%;*/
  height:1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0em;
}
.dsplyContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  opacity: 1;
  /*-ms-flex-pack: distribute;*/
  justify-content: flex-start;
  margin-left:1em;
}
.sliderContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow:2;
  box-sizing: border-box;
  opacity: 1;
  /*-ms-flex-pack: distribute;*/
  justify-content: flex-start;
  width:70%;
  height:7px;
  background: grey;
}
.progress {
  height: 7px;
  background: white;
}
.controlsBottom {
  height: 1px;
  width:100%;
  margin-top: 1em;
  /*margin-bottom: 1em;*/
  box-sizing: border-box;
  border-bottom: 1px solid white;
  padding-bottom: 1em;
}

.TrackList {
  width: 100%;
  height: auto;
  /* overflow-y: auto; */
  position: relative;
}
.TrackList-HomePlayer {
  width: 100%;
  height: auto;
  /* overflow-y: auto; */
  position: relative;
}
@media (min-width: 700px) {
  .homePlayer {
    overflow:auto;
  /*margin: 0px 0px 25px;
  max-height: 600px;*/

}
}

.TrackList li {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  font-weight: 500;
  font-size: 1.2em;
  letter-spacing: 0.12em;
  color: white;
}
/*.player .Ttacklist li::before {
  display: block;
  content: "";
  background: blue;
  background-size: cover;
  opacity: 0.6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
} */

.TrackList li:hover,
.TrackList li.selected {
  background: #5680e9;
  cursor: pointer;
  color: white;
}

.TrackList li:hover .duration {
  opacity: 1;
}

.TrackList li .title {
  padding-left: 10px;
  font-size: .9em;
  width: 80%;
}

.TrackList li .number {
  margin-right: auto;
  padding-left: 5px;
  font-size:.8em;
  font-weight: 300;
  letter-spacing: 0.12em;
  opacity: 0.7;
}

.TrackList li .duration {
  margin-left: 0px;
  opacity: 0.75;
  padding-right: 10px;
  font-size: .7em;
}

.MusicCredit,
.MusicCredit a {
  color: white;
  padding: 15px 10px 20px;
  text-align: center;
}
.MusicCredit p {
  font-size: .5 em;
  color: white;
}
