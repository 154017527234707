.App {
  text-align: center;
}
.navLink {
  color: #03256c !important;
  font-size: 1em;
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
}
.navlink li:hover,
.navlink li.active {
  cursor: pointer;
  /*color: purple !important;
background-color: purple !important; */
}
.active {
  background-color: #03256c !important;
  color: white !important;
}

.bkgnd-navbar {
  opacity: 1;
  background-color: #b5e2f4;
  padding-bottom:0px;
  padding-top:0px;
  margin-bottom:0px;
}
.navbar-spacer {
  margin-top: 80px;
}
.justify-content-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .justify-content-center {
    flex-direction: column;
  }
}
.brand {
  font-family: 'Aladin';
  font-weight: 500;
  color: #03256c !important;
  font-size: 1.5em;
}
.linkStyles {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.remove-gutters {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
}

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
